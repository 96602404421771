import React, { useState } from 'react';
export const LinkContext = React.createContext();

export const LinkContextProvider = ({children}) =>{
    const ScrollToPricing = () => {
        const element = document.getElementById('pricing');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
    const [state , setState] = useState(ScrollToPricing);
    return(
        <LinkContext.Provider value={[state , setState]}>
            {children}
        </LinkContext.Provider>
    )
}
