import { BrowserRouter as Router, Redirect, Switch} from 'react-router-dom';
import { lazy, Suspense, useState } from "react";
import ScrollToTopRoute from './components/scrolltotop/ScrollToTopRoute';
import {FloatingWhatsApp} from "react-floating-whatsapp";
import { LinkContextProvider } from './pages/Contexts/LinkContext';
import { ClipLoader } from 'react-spinners';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useGeoLocation from 'react-ipgeolocation';
import { useEffect } from 'react';
import Text from './assets/Json/Text.json';



const HomeTwo = lazy(() => import("./pages/homes/HomeTwo"));
const ChannelsList = lazy(() => import("./components/index/indextwo/ChannelsList"));
const Contact = lazy(() => import("./pages/contact/Contact"));
const Community = lazy(() => import("./pages/community/Community"));
const FreeTrial = lazy(() => import("./pages/community/FreeTrial"));
const Overview = lazy(() => import("./pages/overview/Overview"));
const AboutUs = lazy(() => import("./pages/aboutpage/aboutuspage/AboutUs"));
const Privacy = lazy(() => import("./pages/blogdetails/Privacy"));
const Refund = lazy(() => import("./pages/blogdetails/Refund"));
function App() {
const location = useGeoLocation();
const [countries, setCountries] = useState(['MA','US','GB','BE']);
const country = location.country;
const [language, setlanguage] = useState(Text.en);
useEffect(() => {
if (location.country) {
  if (!countries.includes(country)) {
    setlanguage(Text.du)
  }
}
});

  return (
    
    <div className="App">
      <ToastContainer />
    {
      <FloatingWhatsApp
          className='FloatingWhatsApp'
          phoneNumber="+212614456046"
          accountName="Screenear"
          chatMessage=" 👋Welcome to Screenear Community, How can we help? 🙂"
          allowClickAway
          avatar="assets/img/logo/LogoSquare1.png"
          notification={true}
          messageDelay={2}
          notificationDelay={60000}
          allowEsc={true}
          darkMode={false}
          notificationSound={false}
        />
        
      }

        <Suspense fallback={<div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
        >
          <ClipLoader
                    color="#e4a101"
                    cssOverride={{}}
                    loading='true'
                    size={150}
                    speedMultiplier={1}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />

        </div>}>
  <LinkContextProvider>
     <Router>
       
        <Switch>
        <ScrollToTopRoute exact={true} path='/'>
          <HomeTwo language={language}/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/contact'>
        <Contact language={language}/>
        </ScrollToTopRoute>
        <ScrollToTopRoute  path='/checkout_:index-:idplan' >
        <Community language={language}/>
        </ScrollToTopRoute>
        {/*
          <ScrollToTopRoute  path='/free-trial' >
          <FreeTrial/>
          </ScrollToTopRoute>
          */
        }
        <ScrollToTopRoute  path='/free-trial-user' >
          <FreeTrial/>
          </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/FAQs'>
        <Overview/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/channels-list'>
        <ChannelsList/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/about-us'>
        <AboutUs/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/privacy-policy'>
        <Privacy/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/refund-policy'>
        <Refund/>
        </ScrollToTopRoute>
        <Redirect to='/'/> 
     </Switch>
     </Router>
    </LinkContextProvider> 
    </Suspense>
     
    </div>
  );
}

export default App;
